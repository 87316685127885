import moment from 'moment';
import 'moment-timezone';
import "magnific-popup"

function runFunctionInDateTimeInterval(startDateTime, endDateTime, timezone, callbackFunction) {
  // Parse the start and end date-times with timezone
  let start = moment.tz(startDateTime, "YYYY-MM-DD HH:mm", timezone);
  let end = moment.tz(endDateTime, "YYYY-MM-DD HH:mm", timezone);
  let now = moment.tz(timezone);

  // Check if current date-time is within the range
  if (now.isBetween(start, end)) {
    callbackFunction();
  } else {
    console.log("Current date-time is not within the given interval for timezone: " + timezone);
  }
}


jQuery(document).ready(function ($) {

  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    // $("#nav-menu").toggleClass('active')
    $("header").toggleClass('active')
    $(".nav-items").toggleClass('active')
    $("#nav-socials").toggleClass('active')
    $(".line").toggleClass('active')
    $(".join-mobile").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('header').hasClass('active')) {
      $("header").toggleClass('active')
      $(".nav-items").toggleClass('active')
      $("#nav-socials").toggleClass('active')
      $(".line").toggleClass('active')
      $(".join-mobile").toggleClass('active')
    }
  })

  // SMOOTH SCROLLING
  function addSmoothScrolling() {
    $('a[href^="#"]').on('click', function (e) {
      e.preventDefault();

      var targetId = $(this).attr("href");
      $('html, body').animate({ scrollTop: $(targetId).offset().top }, 'slow');
    });
  }

  addSmoothScrolling();

  // VIDEO PLAYER OVERLAY
  $('.play-overlay').click(function () {
    $(this).closest('.poster-wrapper').css('display', 'none');
  });

  // VIDEO 
  const featuredVideo = $('#main-video')

  $('.set-featured-video').on('click', function (e) {
    e.preventDefault()

    const videoID = $(this).attr('data-video-id')
    featuredVideo.attr('src', `https://www.youtube.com/embed/${videoID}`)
    $('#main-video-overlay').css('display', 'none');
  })

  // RENDER ALBUMS
  const albums = [
    {
      title: "Pies Descalzaos",
      date: "1995",
      link: "https://SML.lnk.to/PiesDescalzos",
      imgUrl: "../dist/img/covers/PiesDescalzaos1995.png"
    },
    {
      title: "Dónde Están los Ladrones?",
      date: "1998",
      link: "https://SML.lnk.to/DondeEstanLosLadrones",
      imgUrl: "../dist/img/covers/DondeEstanlosLadrones1998.png"
    },
    {
      title: "MTV Plugged",
      date: "2000",
      link: "https://SML.lnk.to/MTVUnplugged",
      imgUrl: "../dist/img/covers/MTVUnplugged2000.png"
    },
    {
      title: "Laundry Service",
      date: "2001",
      link: "https://SML.lnk.to/LaundryService",
      imgUrl: "../dist/img/covers/LaundryService2001.png"
    },
    {
      title: "Live & off the Record",
      date: "2004",
      link: "https://SML.lnk.to/LiveAndOffTheRecord",
      imgUrl: "../dist/img/covers/Live&OfftheRecord2004.jpg"
    },
    {
      title: "Fijación Oral Vol. 1",
      date: "2005",
      link: "https://shakira.lnk.to/FijacionOralVol1",
      imgUrl: "../dist/img/covers/FijacionOralVol1-2005.png"
    },
    {
      title: "Oral Fixation, Vol. 2",
      date: "2005",
      link: "https://SML.lnk.to/OralFixationVol2",
      imgUrl: "../dist/img/covers/OralFixationVol2-2005.png"
    },
    {
      title: "Oral Fixation Tour",
      date: "2007",
      link: "https://SML.lnk.to/OralFixationTourLive",
      imgUrl: "../dist/img/covers/OralFixationTour2007.png"
    },
    {
      title: "She Wolf",
      date: "2009",
      link: "https://SML.lnk.to/SheWolf",
      imgUrl: "../dist/img/covers/SheWolf2009.png"
    },
    {
      title: "Sale el Sol",
      date: "2010",
      link: "https://SML.lnk.to/SaleElSol",
      imgUrl: "../dist/img/covers/SaleelSol2010.png"
    },
    {
      title: "Live from Paris",
      date: "2011",
      link: "https://Shakira.lnk.to/LiveFromParis",
      imgUrl: "../dist/img/covers/LivefromParis2011.png"
    },
    {
      title: "Shakira",
      date: "2014",
      link: "https://sml.lnk.to/Shakira",
      imgUrl: "../dist/img/covers/Shakira2014.png"
    },
    {
      title: "El Dorado",
      date: "2017",
      link: "https://SML.lnk.to/ElDorado",
      imgUrl: "../dist/img/covers/ElDorado2017.png"
    },
    {
      title: "Shakira in Concert: El Dorado World Tour",
      date: "2019",
      link: "https://SML.lnk.to/ElDoradoWorldTour",
      imgUrl: "../dist/img/covers/ShakirainConcertElDoradoWorldTour2019.png"
    },
    {
      title: "Las Mujeres Ya No Lloran",
      date: "2024",
      link: "https://SML.lnk.to/LMYNL",
      imgUrl: "../dist/img/covers/LasMujeresYaNoLloran2024.png"
    }
  ];


  const albumList = $("#album-list");
  const sortSelect = $("#sort");

  // Function to create a card element
  function createCard(album) {
    const card = $('<div class="card"></div>');
    const img = $(`<img src="${album.imgUrl}" alt="${album.title}" class="cover">`);
    const titleAndLink = $(`<div class="info-wrapper"><div class="title">${album.title}, ${album.date}</div><a href="${album.link}" target="_blank">Listen</a></div>`)

    card.append(img, titleAndLink);
    return card;
  }

  // Function to render the album list
  function renderAlbums(albumsArray) {
    albumList.empty();
    albumsArray.forEach(album => {
      const card = createCard(album);
      albumList.append(card);
    });
  }

  function sortByTitle(albums) {
    albums.sort((a, b) => a.title.localeCompare(b.title));
  }

  function sortByRecentDate(albums) {
    albums.sort((a, b) => parseInt(b.date) - parseInt(a.date));
  }

  function sortByOldestDate(albums) {
    albums.sort((a, b) => parseInt(a.date) - parseInt(b.date));
  }

  sortSelect.on("change", function () {
    const selectedValue = $(this).val();

    if (selectedValue === 'title') {
      sortByTitle(albums);
    } else if (selectedValue === 'new-old') {
      sortByRecentDate(albums);
    } else if (selectedValue === 'old-new') {
      sortByOldestDate(albums);
    }
    renderAlbums(albums);
  });


  sortByRecentDate(albums)
  renderAlbums(albums);

  // FORM
  $('.newsletter').on('submit', function (e) {
    e.preventDefault();
    const DATA = $(this).serialize();

    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      dataType: 'json',
      data: DATA,
      xhrFields: {
        withCredentials: false,
      },
      success: function (data) {
        $('.inputs-wrap').html('<p class="newsletter-thanks">Thank you!</p>');
      },
      error: function (err) {
        console.log(err);
      }
    });
  });

  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_90/events?app_id=45PRESS_shakira',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!')
    },
    success: data => {
      const events = $('#tour-dates')
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<div class="event-group">'
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>'
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>'
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>'
          html += '<div class="event-links">'
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>'
          html += '</div>'
        }
        events.html(html)
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>')
      }
      if (n < 9) {
        $("#toggle-dates").hide()
      }
    }
  })

  // COUNTDOWN
  var countDownDate = moment.tz("2024-04-16 08:00", "America/New_York");

  var x = setInterval(function () {
    var now = moment();
    var distance = countDownDate.diff(now);

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    $(".days10").text(Math.floor(days / 10));
    $(".days1").text(days % 10);
    $(".hours10").text(Math.floor(hours / 10));
    $(".hours1").text(hours % 10);
    $(".minutes10").text(Math.floor(minutes / 10));
    $(".minutes1").text(minutes % 10);
    $(".seconds10").text(Math.floor(seconds / 10));
    $(".seconds1").text(seconds % 10);

    if (distance < 0) {
      clearInterval(x);
      $('.countdown').hide();
      $(".preorder").text("Listen Now");
    }
  }, 1000);



  //POPUP
  runFunctionInDateTimeInterval("2024-03-26 17:15", "2024-03-26 19:35", "America/Detroit", function () {
    $.magnificPopup.open({
      items: {
        src: 'https://www.youtube.com/watch?v=HOse5_hGcSw',
        type: 'iframe'
      }
    });
  });

  $('#verified-form').on('submit', function (e) {
    e.preventDefault();
    const DATA = $(this).serialize();

    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      dataType: 'json',
      data: DATA,
      xhrFields: {
        withCredentials: false,
      },
      success: function (data) {
        $('#fanclub-page-content').hide()
        $('.inputs-wrap').html('<p class="newsletter-thanks" style="text-align: center; font-size: 1.25rem;">Thank you for submitting. Please check your email for more information!</p>');
      },
      error: function (err) {
        console.log(err);
      }
    });
  });
}); 